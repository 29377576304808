<template>
  <div class="login-page">
    <div class="content-shell">
      <div class="title-box">
        <span class="name">晓知因</span>
        <span class="tip">AI智控机器人</span>
      </div>
      <div class="login-content">
        <div class="visual-area">
          <img class="gif" src="./img/box.gif" alt="">
          <img class="gif-bot" src="./img/gif-bot.png" alt="">
        </div>
        <div class="content-area">
          <!-- 验证码登录 -->
          <div v-if="type === 1" class="verify-code">
            <img class="qrcode-corner" src="./img/qrcode.png" @click="type = 2">
            <div class="content-title">验证码登录</div>
            <div class="code-content">
              <div class="form-item">
                <img class="icon" src="./img/phone.png" alt="">
                <el-input placeholder="请输入手机号" v-model="form.phone" maxlength="11" />
                <div :class="['code-btn', countdown && 'disabled']" @click="handCode">{{ !countdown ? '获取验证码' :
            `${countdown}S后重新获取` }}</div>
              </div>
              <div class="form-item">
                <img class="icon" src="./img/code.png" alt="">
                <el-input placeholder="请输入验证码" v-model="form.code" />
              </div>
              <p class="change-tip" @click="type = 2">收不到验证码？</p>
              <div class="policy-box">
                <el-checkbox v-model="checked"> </el-checkbox>
                <span class="label">同意<span class="act">隐私政策</span>和<span class="act">服务条款</span>，未注册的手机号将自动创建账号</span>
              </div>
              <div class="login-btn" @click="handleLogin">登录</div>
            </div>
          </div>

          <!-- 扫码登录 -->
          <div v-else class="scan-qrcode">
            <div class="content-title">扫码登录</div>
            <img class="qrcode" src="./img/qrcode.png" alt="">
            <div class="change-tip" @click="type = 1">
              <span class="label">验证码登录</span>
              <div class="icon">
                <img src="./img/pc-icon.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { checkMobile } from '@/utils/index'
import { sendCode, phoneLogin } from '@/api/user'
let timer = null
export default {
  name: 'index',
  data() {
    return {
      type: 1, // 1 验证码登录 2扫码
      form: {
        phone: '',
        code: '',

      },
      countdown: '',
      checked: true,
      // loginlist: ['17374617613', '13125250997', '18797812717', '15511818628', '17736766654', '18607078081', '13825627276', "18657165888", "15081468062", "18576712607", "13510879295", "13609888662", "15031617621", "18970795193", "13696850615", "18680467059", "18806505916", "17324386047", "15013131185", "18720739997", "15127614530", "13517076087", "13507977978", "15057131650", "15901254816", "18676889866", "18818807377", "15919424684", "15770767177", '18923748186', '17796646175', '15070216300', '15392873090', '13867103786', '18720978735', '18057127368', '13621266846', '17826823305', '13429616209', '18007099098', "18370766798", "15279702769", "13928785070", "18770811415", "18720820628", "13231688333", "18879730778", "18460365390", "13032521286", "18370780457", "18503150628", "15012971920", "17679394106", "18870710935", "18838050275", "15133653716", "18533603327", "15012683419", "15047940150", "18770753788", "13970107728", "13125278902", "13617061715", "18370747833", "18606618788", "19136900113", "15179746001", "18296787585", "19700719276", "13932607933", "15354368887", "19970981611", "18530099022", "18638112781", "18538116765", "13592459793", "15713806135", "15369626322", "15931606408", "13131651993", "15100606424", "15990089691", "13622985418", "18146687658", "18470750997", "15858163443", "17720763732"]
    }
  },
  computed: {
    ...mapGetters("user", ["token"]),
  },
  mounted() {
    // let text = JSON.stringify({ "id": "00152225-4518" }) //原始字符串
    // console.log('原始字符串', text)
    // text = encryptData(text)
    // console.log('AES加密', encryptData(text))
    // text = Base64.encode(text)
    // console.log('base64加密', Base64.encode(text))
    // console.log('最终加密串--------', text)
    // text = 'J329lipXi1j+sm+cDkLd4tEQnt5dA600s7Q/SG9q3d4='
    // text = Base64.decode(text)
    // console.log('base64解密', Base64.decode(text))
    // text = decryptData(text)
    // console.log('AES解密', decryptData(text))
    // console.log('解密后字符串', text)

  },
  methods: {
    ...mapMutations('user', ['updateUserInfo']),

    /** 获取验证码 */
    getCode() {
      sendCode({
        phone: this.form.phone
      }).then(res => {
        console.log('验证码--', res)
      })
    },

    /** 点击获取验证码 */
    handCode() {
      if (this.countdown) return
      if (!this.form.phone) {
        return this.$message.warning('请输入手机号')
      }
      if (!checkMobile(this.form.phone)) {
        return this.$message.warning('请输入正确的手机号')
      }
      if (timer) clearInterval(timer)
      // console.log(this.loginlist)
      // if (this.loginlist.indexOf(this.form.phone) >= 0) {
      sendCode({ phone: this.form.phone }).then(res => {
        if (res.code !== 200) return this.$message.error(res.msg || '获取失败，请稍后再试~')
        this.countdown = 59
        timer = setInterval(() => {
          this.countdown--
          if (this.countdown <= 0) {
            clearInterval(timer)
          }
        }, 1000)
      })
      // } else {
      //   this.$message.warning('系统调试中，暂停登录系统，请稍后重试')
      // }

    },

    /** 登录 */
    handleLogin() {
      if (!this.checked) {
        return this.$message.warning('请先勾选隐私政策和服务条款！')
      }
      if (!this.form.phone) {
        return this.$message.warning('请输入手机号')
      }
      if (!checkMobile(this.form.phone)) {
        return this.$message.warning('请输入正确的手机号')
      }
      if (!this.form.code) {
        return this.$message.warning('请输入验证码')
      }
      const params = {
        phone: this.form.phone,
        code: this.form.code
      }
      phoneLogin(params).then(res => {
        console.log('登录--', res)
        if (res.code !== 200) return this.$message.error(res.msg || '登录失败，请稍后再试~')
        this.updateUserInfo(res.data)
        this.$message.success('登录成功！正在跳转...')
        setTimeout(() => {
          this.$router.push({ path: '/home' })
        }, 500)
      })
    }
  },
  destroyed() {
    if (timer) clearInterval(timer)
  }
}
</script>

<style scoped lang="less">
.login-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url('./img/bg.png') no-repeat center center;

  .content-shell {
    margin: 0 auto;
    padding-top: 3vh;
    width: 1110px;

    .title-box {
      position: relative;
      width: 359px;
      height: 178px;
      margin: 0 auto;
      background: url('./img/line.png') no-repeat center center;

      .name,
      .tip {
        position: absolute;
        color: #fff;
        font-family: YouSheBiaoTiHei;
      }

      .name {
        top: 44px;
        left: 10px;
        font-size: 50px;
      }

      .tip {
        top: 70px;
        left: 211px;
        font-size: 48px;
        white-space: nowrap;
      }
    }

    .login-content {
      display: flex;
      background: #FFFFFF;
      box-shadow: 0px 28px 35px 0px rgba(0, 52, 184, 0.21);

      .visual-area {
        width: 567px;
        text-align: center;
        background-color: #3190FD;

        // flex-shrink: 1;
        .gif {
          width: 466px;
          margin-top: 20px;
          object-fit: cover;
        }

        .gif-bot {
          margin-top: -100px;
        }
      }

      .content-area {
        position: relative;
        flex: 1;

        .content-title {
          padding-top: 90px;
          font-size: 24px;
          color: #2A90FF;
          text-align: center;
        }

        .verify-code {
          position: relative;
          height: 100%;

          .qrcode-corner {
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
            /* 设置裁剪路径为三角形 */
            position: absolute;
            right: 10px;
            top: 10px;
          }

          .code-content {
            margin: 50px 70px;

            .form-item {
              position: relative;
              display: flex;
              justify-content: space-between;
              margin-top: 35px;

              .icon {
                position: absolute;
                top: 13px;
                left: 16px;
                width: 22px;
                height: 22px;
                z-index: 1;
              }

              /deep/.el-input {
                flex: 1;

                .el-input__inner {
                  padding-left: 54px;
                  height: 48px;
                  line-height: 48px;
                }
              }

              .code-btn {
                width: 128px;
                line-height: 48px;
                margin-left: 24px;
                text-align: center;
                color: #fff;
                font-size: 16px;
                background: linear-gradient(135deg, #5C8DF7 0%, #2A90FF 100%);
                border-radius: 2px;
                cursor: pointer;

                &.disabled {
                  background: #eee;
                  color: #666;
                  cursor: not-allowed;
                }
              }
            }

            .change-tip {
              margin-top: 20px;
              text-align: right;
              color: #647382;
              font-size: 16px;
              cursor: pointer;
            }

            .policy-box {
              margin: 58px 0 18px;

              .label {
                margin-left: 8px;
                font-size: 14px;
                color: #777A86;

                .act {
                  color: #4B8EF9;
                }
              }
            }

            .login-btn {
              line-height: 48px;
              text-align: center;
              color: #fff;
              font-size: 16px;
              background: linear-gradient(135deg, #5C8DF7 0%, #2A90FF 100%);
              cursor: pointer;
            }
          }
        }

        .scan-qrcode {
          position: relative;
          height: 100%;
          text-align: center;

          .qrcode {
            width: 246px;
            height: 246px;
            margin-top: 34px;
          }

          .change-tip {
            width: 100%;
            padding: 16px 26px;
            position: absolute;
            bottom: 0;
            background-color: #F8FAFF;
            text-align: right;
            cursor: pointer;

            .label,
            .icon {
              display: inline-block;
              vertical-align: middle;
            }

            .label {
              margin-right: 14px;
              color: #647382;
              font-size: 20px;
            }

            .icon {
              padding: 18px;
              background-color: #E9F0FC;
              border-radius: 4px;
              backdrop-filter: blur(10px);

              img {
                width: 26px;
                height: 26px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
